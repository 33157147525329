<template>
<section id="pricing-plan">

    <b-modal id="modal-scrollable" scrollable title="使用條款及細則" cancel-title="關閉" ok-title="確定" only-ok size="lg" cancel-variant="outline-secondary">
        <b-card-text v-for="(content,index) in scrollContent" :key="index">
            {{ content }}
        </b-card-text>
    </b-modal>

    <!-- title text and switch button -->
    <div class="text-center">

        <h1 class="mt-1">
            個人教練計劃
        </h1>
        <p class="mb-2 pb-75">
            <!-- All plans include 40+ advanced tools and features to boost your product. Choose the best plan to fit your needs. -->
        </p>

    </div>
    <!--/ title text and switch button -->

    <!-- pricing plan cards -->
    <b-row class="pricing-card">
        <b-col sm="12" md="12" lg="12" class="mx-auto">
            <b-row>
                <b-col>
                    <b-overlay :show="monthlyOverlay" spinner-type="gr1ow" spinner-small rounded="sm">
                        <b-card align="center">

                            <!-- img -->
                            <b-img v-if="pricing.basicPlan.img" :src="require('@/assets/images/illustration/Pot1.svg')" class="mb-2 mt-5" alt="basic svg img" />
                            <!--/ img -->
                            <h3>月費計劃</h3>
                            <b-card-text>
                                每月自動於信用卡繳費
                            </b-card-text>

                            <!-- annual plan -->
                            <div class="annual-plan">
                                <div class="plan-price mt-2">
                                    <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                                    <span class="pricing-basic-value font-weight-bolder text-primary">348</span>
                                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                                </div>

                            </div>
                            <!--/ annual plan -->

                            <b-button v-if="selected === false" v-ripple.400="'rgba(40, 199, 111, 0.15)'" block class="mt-2" variant="outline-primary" @click="selectedPlan('monthly')">
                                確定
                            </b-button>
                            <b-button v-if="selected === true" v-ripple.400="'rgba(40, 199, 111, 0.15)'" block class="mt-2" variant="outline-warning" @click="selectedPlan('back')">
                                返回
                            </b-button>
                        </b-card>
                    </b-overlay>
                </b-col>
                <b-col>
                    <b-overlay :show="annuallyOverlay" spinner-type="gr1ow" spinner-small rounded="sm">
                        <b-card class="popular" align="center">

                            <div class="pricing-badge text-right">
                                <b-badge variant="light-primary" pill>
                                    最多教練選用
                                </b-badge>
                            </div>
                            <!-- img -->
                            <b-img :src="require('@/assets/images/illustration/Pot2.svg')" class="mb-1" alt="svg img" />
                            <!--/ img -->
                            <h3>年繳計劃</h3>
                            <b-card-text>繳付一年費用，簡單方便</b-card-text>

                            <!-- annual plan -->
                            <div class="annual-plan">
                                <div class="plan-price mt-2">
                                    <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                                    <span class="pricing-basic-value font-weight-bolder text-primary">298</span>
                                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                                </div>
                            </div>

                            <b-button v-if="selected === false" v-ripple.400="'rgba(255, 255, 255, 0.15)'" block class="mt-2" variant="primary" @click="selectedPlan('annually')">
                                確定
                            </b-button>
                            <b-button v-if="selected === true" v-ripple.400="'rgba(40, 199, 111, 0.15)'" block class="mt-2" variant="outline-warning" @click="selectedPlan('back')">
                                返回
                            </b-button>
                        </b-card>
                    </b-overlay>
                </b-col>
                <b-col>
                    <b-overlay :show="twoYearOverlay" spinner-type="g1row" spinner-small rounded="sm">
                        <b-card align="center">

                            <!-- img -->
                            <b-img v-if="pricing.enterprisePlan.img" :src="require('@/assets/images/illustration/Pot3.svg')" class="mb-2" alt="enterprise svg img" />
                            <!--/ img -->
                            <h3>兩年預繳</h3>
                            <b-card-text>性價比最高之計劃</b-card-text>

                            <!-- annual plan -->
                            <div class="annual-plan">
                                <div class="plan-price mt-2">
                                    <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                                    <span class="pricing-basic-value font-weight-bolder text-primary">238</span>
                                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                                </div>
                            </div>
                            <!--/ annual plan -->

                            <!-- buttons -->
                            <b-button v-if="selected === false" v-ripple.400="'rgba(113, 102, 240, 0.15)'" block class="mt-2" variant="outline-primary" v-b-toggle.payment @click="selectedPlan('twoYear');">
                                確定
                            </b-button>
                            <b-button v-if="selected === true" v-ripple.400="'rgba(40, 199, 111, 0.15)'" block class="mt-2" variant="outline-warning" @click="selectedPlan('back')">
                                返回
                            </b-button>
                        </b-card>
                    </b-overlay>
                </b-col>
             
                <b-col v-show="plan.name == 'monthly'" lg="4">
                    <b-overlay :show="processing" spinner-type="grow" spinner-small rounded="sm">
                    <b-card>
                        <h5>Credit Card Information 信用卡支付</h5>
                        <b-form class="mt-2">

                            <b-form-group label="Credit Card Number" label-for="card">
                                <b-form-input id="card" v-mask="credit_card_format" v-model="card.number" trim />

                            </b-form-group>

                            <b-form-group label="Expiry Date (YYYY-MM)" label-for="Expiry Date">
                                <b-form-input id="expire" v-mask="'####-##'" v-model="card.expire" trim />

                            </b-form-group>

                            <b-form-group label="CVC" label-for="cvc">
                                <b-form-input id="cvc" v-mask="cvc_format" v-model="card.cvc" trim />
                            </b-form-group>
                            <br>
                                <hr />
                                <b>總額 : HKD ${{ plan.amount.toFixed(2) }}</b><br><br>
                                <span class="text-danger"> * 每月會於此信用卡內扣除月費 *</span>
                            <b-form-checkbox id="register-privacy-policy" v-model="plan.agree" name="checkbox-1">
                                我同意 <b-link style="font-weight: bold;" v-b-modal.modal-scrollable>私隱條款及使用守則</b-link><br>

                            </b-form-checkbox>
                        </b-form>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" block class="mt-2" variant="success" @click="stripe_post();">
                            進行付款
                        </b-button>
                    </b-card>
                    </b-overlay>
                </b-col>
                
               
                <b-col v-show="plan.name == 'annually' || plan.name == 'twoYear'" lg="4">
                    <b-overlay :show="processing" spinner-type="grow" spinner-small rounded="sm">
                    <b-card>
                        <h5>一次性支付</h5>
                        <b-form class="mt-2">
                            系統將跳轉至網上支付平台
                            <br>
                                <hr />
                                <b>總額 : HKD ${{ plan.amount.toFixed(2) }}</b><br><br>
                            <b-form-checkbox id="register-privacy-policy" v-model="plan.agree" name="checkbox-1">
                                我同意 <b-link style="font-weight: bold;" v-b-modal.modal-scrollable>私隱條款及使用守則</b-link><br>

                            </b-form-checkbox>
                        </b-form>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" block class="mt-2" variant="success" @click="pfpay_post();">
                            進行付款
                        </b-button>
                    </b-card>
                </b-overlay>
                </b-col>
                
            </b-row>
        </b-col>
    </b-row>
    <!--/ pricing plan cards -->
    <!-- pricing free trial -->
    <div class="pricing-free-trial">
        <b-row>
            <b-col lg="10" offset-lg="3" class="mx-auto">
                <div class="pricing-trial-content d-flex justify-content-between">
                    <div class="text-center text-md-left mt-3">
                        <h3 class="text-primary">
                            還有疑慮 或 問題？
                        </h3>
                        <h5>可以即時聯絡我們，了解更多</h5>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mt-2 mt-lg-3" href="https://wa.me/85244464116" target="_blank">
                            <font-awesome-icon :icon="['fab', 'whatsapp']" /> &nbsp;&nbsp;按此 whatsapp 聯絡我們
                        </b-button>
                    </div>

                    <!-- images -->
                    <b-img fluid :src="require('@/assets/images/illustration/pricing-Illustration.svg')" class="pricing-trial-img" alt="svg img" />
                    <!--/ images -->
                </div>
            </b-col>
        </b-row>
    </div>
    <!--/ pricing free trial -->

</section>
</template>

<script>
import {
    BFormCheckbox,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BLink,
    BModal,
    BImg,
    BCardText,
    BListGroup,
    BListGroupItem,
    BButton,
    BBadge,
    BInputGroup,
    BFormInput,
    BForm,
    BFormGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BCollapse,
    VBToggle
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
/* eslint-disable global-require */
export default {
    components: {
        BFormCheckbox,
        BOverlay,
        BButton,
        BLink,
        BCardText,
        VBToggle,
        BListGroup,
        ToastificationContent,
        BListGroupItem,
        BCollapse,
        BFormGroup,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroupPrepend,
        BForm,
        BRow,
        BCol,
        BModal,
        BCard,
        BBadge,
        BImg,
        AppCollapseItem,
        AppCollapse,
    },
    directives: {
        Ripple,
        'b-toggle': VBToggle,
    },
    watch: {
        "card.number": {
            handler(newValue, oldValue) {
                switch (newValue.substring(0, 1)) {
                    case "3":
                        this.credit_card_format = '#### ###### #####'
                        this.cvc_format = '####'
                        break;
                    default:
                        this.credit_card_format = '#### #### #### ####'
                        this.cvc_format = '###'
                }
            }
        }
    },
    data() {
        return {
            qfpay:null,
            stripe_return: null,
            status: 'monthly',
            processing: false,
            monthlyPlanShow: true,
            loading: false,
            pricing: {},
            now: false,
            monthlyOverlay: false,
            annuallyOverlay: false,
            twoYearOverlay: false,
            plan: {
                agree: false,
                name: null,
                from: '',
                to: '',
                amount: 0,
            },
            selected: false,
            credit_card_format: '#### #### #### ####',
            cvc_format: '###',
            card: {
                number: null,
                expire: null,
                cvc: null,
                center_id: this.$store.getters['center/currentCenterId']
            },
            scrollContent: [
                '- 平台有提供匯出系統所有資料及數據功能，客戶有責任定期自行備份相關資料及數據，我司不保證或擔保任何您可能經由本服務儲存或使用之內容不會遭受在符合本協議條款下之無意的損害、毀壞、損失或移除。 我司對該等損害、毀壞、損失或移除之發生不負責任。',
                '- 如客戶於繳款期內 (繳款期一般為收據發出日起計 7 天) 未有繳交全部或部份費用，平台會自動限制後台相關功能 或 暫停平台之使用權，於欠款期內 ,我司不會向客戶提供任何平台內資料及數據，直至客戶繳交全部之費用及因逾期繳款而衍生之手續費 (手續費為逾期繳款之費用 5%)。',
                '- 如計劃內包括 WHATSAPP 功能 , 此功能為非 WHATSAPP 官方之 API , 我司並不保證所有訊息能順利發出。如需使用WHATSAPP官方功能 , 請聯絡我司並進行報價。',
                '- 當此合約完結而系統功能沒有增加，新合約費用有機會因成本而進行調整，增幅將不會超過此合約之 5％。',
                '- 逾期繳款起計超過 60 天，客戶會被視作放棄平台使用，我司會即時銷毀所有客戶於系統內之所有相關資料及數據。',
                '- 平台服務合約不得轉讓或變賣。',
                '- 系統內所有資料由客戶自行輸入。',
                '- 如選擇月費計劃，系統會於每月訂閱人自動於第一次繳費之信用卡扣除費用，如需更改信用卡資料或取消服務，可於系統設定內自行更改',
                '- 如往後想增加任何程式功能，會另外再發報價。',
                '- 系統內所有客人及用戶資料均屬客戶擁有並由系統加密客人之部分敏感資料 , 資料不會向第三方披露任何以上資料 , 客戶提出終止合約 , 會於客戶繳交合作期內所有費用後三個工作日內以 xlsx 客戶發出資料庫內所有已解密資料 , 發出後並會銷毀所有資料。',
                '- 系統運行方式為雲端執行 , 客戶需確保電腦可穩定連接互聯網。',
                '- 如客戶於合作期內提出終止合約，需於提出終止合約後 15 天內一次性繳交合作期內所剩餘月費之所有費用。',
                '- 我司有權隨時修改本協議，並就您對本服務之使用實施全新或額外的條款或條件，但前提是 我司 必須在 30 天前經電郵向客戶告知本服務或適用服務條款的重大不利變更，除非因下列原因而使此舉變得不合理：源於法律、監管或政府行動的情況；為瞭解決使用者安全、使用者隱私權或技術完整性方面的疑慮；為了避免其他使用者的服務中斷；或因天災、災難性事件、伺服器入侵或攻擊、戰爭，或 我司 無法合理控制的其他類似事件。就服務而言，我司 不會在您目前的付費期間結束前對本服務做出任何重大不利變更，除非該變更在下列情況下為合理且必要：為了應對法律、監管或政府行動；為瞭解決使用者安全、使用者隱私權或技術完整性方面的疑慮；為了避免其他使用者的服務中斷；或為了避免因天災、災難性事件、伺服器入侵或攻擊、戰爭，或 我司無法合理控制之其他類似事件所導致的問題。若 我司 對本服務或使用條款做出重大不利變更，您有權終止本協議，在此情況下，我司會將當前適用付費期間的任何預付款按比例退款給客戶。'
            ],
        }
    },
    created() {
        this.$http.get('/pricing/data').then(res => {
            this.pricing = res.data
        })
    },
    methods: {

        async selectedPlan(type) {
            this.selected = true
            switch (type) {
                case "back":
                    this.selected = false
                    this.plan.name = null
                    this.monthlyOverlay = false
                    this.annuallyOverlay = false
                    this.twoYearOverlay = false
                    break;
                case "monthly":
                    console.log("monthly")
                    this.monthlyOverlay = false
                    this.annuallyOverlay = true
                    this.twoYearOverlay = true
                    this.plan.name = type
                    this.plan.amount = 348
                    break;
                case "annually":
                    this.monthlyOverlay = true
                    this.annuallyOverlay = false
                    this.twoYearOverlay = true
                    this.plan.name = type
                    this.plan.amount = 3576
                    break;
                case "twoYear":
                    this.monthlyOverlay = true
                    this.annuallyOverlay = true
                    this.twoYearOverlay = false
                    this.plan.name = type
                    this.plan.amount = 5712
                    break;
            }
            //this.loading = false
        },
        stripe_post() {
            this.processing = true
            if (this.plan.agree !== true) {
                this.$toast({
                component: ToastificationContent,
                    position: 'top-center',
                    props: {
                        title: '還差一步',
                        icon: 'AlertCircleIcon',
                        variant: 'warning',
                        text: '你必須同意 私隱條款及使用守則',
                    },
                    })
                    this.processing = false
                return
            }
            let order = {
                amount : this.plan.amount,
                code : this.plan.name,
                number: this.card.number,
                cvc : this.card.cvc,
                expire : this.card.expire
            }
            this.$http.post(process.env.VUE_APP_API_BASE+'/payment_gateway/stripe/client_pay/',order)
            .then(res => {
                if (res.data.result === false) {
                    this.$toast({
                    component: ToastificationContent,
                    position: 'top-center',
                    props: {
                        title: '付款錯誤',
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                        text: res.data.message,
                    },
                    })
                    this.processing = false
                    return  
                } else {
                    this.$router.push('/apps/config/renew-payment/order/' + res.data.order_id + '/').then(() => {}) 
                }
            })
        },
        pfpay_post() {
            this.processing = true
            if (this.plan.agree !== true) {
                this.$toast({
                component: ToastificationContent,
                    position: 'top-center',
                    props: {
                        title: '還差一步',
                        icon: 'AlertCircleIcon',
                        variant: 'warning',
                        text: '你必須同意 私隱條款及使用守則',
                    },
                    })
                    this.processing = false
                return
            }
            let order = {
                amount : this.plan.amount,
                code : this.plan.name
            }
            this.$http.post(process.env.VUE_APP_API_BASE+'/payment_gateway/qfpay/',order)
            .then(res => {
                this.qfpay = res.data
                window.location.href = this.qfpay.url
            })
            },
    },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
